import React from 'react'
import { BottomLinks } from '../components/BottomLinks'
import Layout from '../components/Layout'

export default function NotFound() {
  return (
    <Layout>
      <section className="font-sans text-stone-700 text-center">
        <p className="pt-6 md:pt-10 font-semibold text-base md:text-lg text-stone-400">
          404 - Page Not Found
        </p>
        <h1 className="pt-6 md:pt-10 font-bold text-2xl md:text-3xl">
          Hmm...are you lost?
        </h1>
        <h2 className="pt-6 md:pt-10 font-semibold text-base md:text-lg space-y-2">
          <p>No worries! It's easy to get lost sometimes, and that's okay.</p>
          <p>Just click/tap on this Home button right below. It will take you right back home!</p>
        </h2>
        <BottomLinks />
      </section>
    </Layout>
  )
}